const BACKEND_ARAFAT_URLS = {
    PROD: "https://arafat-backend.tsleem.com.sa/",
    THECODE: "https://arafatback.thecode.sa/",
    DEV: "https://arafat-backend-dev.tsleem.com.sa/",
    LOCAL_HOST: "http://127.0.0.1:8000/",
};

const FRONT_ARAFAT_URLS = {
    PROD: "https://arafat.tsleem.com.sa/",
    LOCAL_HOST: "http://localhost:8080/",
    THECODE: "https://arafat.thecode.sa/",
    DEV: "https://arafat-dev.tsleem.com.sa/",
};



export const CURRENT_ARAFAT_URL_BACK = BACKEND_ARAFAT_URLS.THECODE
export const CURRENT_ARAFAT_URL_FRONT = FRONT_ARAFAT_URLS.THECODE



const BACKEND_MOZDALIFA_URLS = {
    PROD: "https://muzdalifa-backend.tsleem.com.sa/",
    THECODE: "https://muzdalifaback.thecode.sa/",
    DEV: "https://muzdalifa-backend-dev.tsleem.com.sa/",
    LOCAL_HOST: "http://127.0.0.1:8000/",
};


const FRONT_MOZDALIFA_URLS = {
    PROD: "https://muzdalifa.tsleem.com.sa/",
    LOCAL_HOST: "http://localhost:8080/",
    THECODE: "https://muzdalifa.thecode.sa/",
    DEV: "https://muzdalifa-dev.tsleem.com.sa/",
    ARAFAT: "https://arafat-dev.tsleem.com.sa/"
};

export const CURRENT_MOZDALIFA_URL_BACK = BACKEND_MOZDALIFA_URLS.THECODE
export const CURRENT_MOZDALIFA_URL_FRONT = FRONT_MOZDALIFA_URLS.THECODE

const BACKEND_GOV_URLS = {
    PROD: "https://arafat-backend.tsleem.com.sa/",
    THECODE: "https://arafatback.thecode.sa/",
    DEV: "https://arafat-backend-dev.tsleem.com.sa/",
    LOCAL_HOST: "http://127.0.0.1:8000/",
};

const FRONT_GOV_URLS = {
    PROD: "https://arafat.tsleem.com.sa/",
    LOCAL_HOST: "http://localhost:8080/",
    THECODE: "https://arafat.thecode.sa/",
    DEV: "https://arafat-dev.tsleem.com.sa/",
};



export const CURRENT_GOV_URL_BACK = BACKEND_GOV_URLS.THECODE
export const CURRENT_GOV_URL_FRONT = FRONT_GOV_URLS.THECODE


