<!-- /* background-color: rgba(199, 152, 113, 0.7); */ -->
<template>
  <v-app>
    <div
      style="
        background-color: rgba(6, 42, 113, 0.7);
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 0;
      "
    ></div>
    <div
      class="auth-page bg-page-login"
      style="height: 100%"
      :style="
        $route.name == 'Signup'
          ? `background-image:  url(${require('../../assets/img/tsleem/bg-login.png')}`
          : `background-image:  url(${require('../../assets/img/tsleem/login-min.jpg')};`
      "
    >
      <!-- <img
        src="@/assets/img/tsleem/Pattern-02.png"
        class="pattern pattern-1"
        width="300"
      /> -->

      <div
        style="
          width: 100%;
          height: 100%;
          margin: 0%;
          margin-right: auto;
          position: relative;
          z-index: 1;
        "
        s
      >
        <div class="h-100">
          <!-- <v-col cols="12" v-if="$route.name == 'switch'">

          </v-col> -->
          <!-- <v-col cols="5"> </v-col> -->
          <!-- <v-col
            :xl="colsXl"
            :lg="colsLg"
            :md="colsMd"
            :sm="colsSm"
            cols="12"
            class="d-flex flex-column"
            style="background-color: white"
          > -->
          <div
            class="d-flex flex-column justify-center w-lg-40 h-100 ml-lg-auto"
            style="background-color: white"
          >
            <a href="https://tsleem.com.sa/">
              <img
                src="@/assets/img/tsleem/logo-2.png"
                class="navbar-brand-img ms-3 mb-3 mt-3 brand d-none d-md-block"
                width="210"
              />
            </a>
            <img
              src="@/assets/img/tsleem/Pattern-03.png"
              class="pattern pattern-1"
              width="200"
            />
            <!-- <div class="col-md-6 col-sm-8 col-lg-5 col-xl-4 col-xxl-3 mx-auto"> -->
            <fade-transition :duration="200" origin="center top" mode="out-in">
              <!-- your content here -->
              <router-view></router-view>
            </fade-transition>
          </div>
        </div>
      </div>

      <img
        src="@/assets/img/tsleem/Pattern-02.png"
        class="pattern pattern-2 d-none d-md-block"
        width="300"
      />
      <!-- <img
        src="@/assets/img/tsleem/Pattern-03.png"
        class="pattern pattern-2 d-block d-lg-none"
        width="200"
      /> -->
      <!-- end container fluid -->
    </div>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {};
  },
  computed: {
    colsXl() {
      if (this.$route.name == "Login") return 4;
      else if (this.$route.name == "switch") return 7;
      else return 5;
    },
    colsLg() {
      if (this.$route.name == "Login") return 7;
      else if (this.$route.name == "switch") return 8;
      else return 6;
    },
    colsMd() {
      if (this.$route.name == "Login") return 6;
      else if (this.$route.name == "switch") return 9;
      else return 7;
    },
    colsSm() {
      if (this.$route.name == "Login") return 8;
      else if (this.$route.name == "switch") return 10;
      else return 9;
    },
  },
};
</script>
<style>
.brand {
  position: absolute;
  top: 40%;
  left: 22%;
}
.pattern {
  z-index: 3;
  position: absolute;
}
.pattern-2 {
  bottom: 0;
  width: 300px;
  left: 0;
}
.pattern-1 {
  top: 0;
  width: 300px;
  right: 0;
  transform: rotate(180deg);
}
@media (min-width: 992px) {
  .w-lg-40 {
    width: 40%;
  }
}
@media (max-width: 780px) {
  .pattern-1 {
    width: 250px;
  }
}
</style>
